<template>
  <div class="dashboard1 p-5" id="dementie-information">
    <div class="row">
        <div class="col-sm-12 down">
          <div>
            <h1 class="intro-section-title" v-if="selectedLevel === 'zorgkantoor' || selectedLevel === 'dementienetwerk'">5. Medicatie, psychofarmaca en polyfarmacie {{selectedYear}}</h1>
            <h1 class="intro-section-title" v-if="selectedLevel === 'landelijk'">5. Medicatie, psychofarmaca en polyfarmacie</h1>
            <div class="row">
              <p class="intro-section-text">
                <b>Populatie:</b> Deze informatie heeft alleen betrekking op mensen met dementie die het hele jaar geen Wlz-zorg gebruikten. Het gaat hier dus om een deel van de totale populatie thuiswonende mensen met dementie.
              </p>
            </div>
          </div>
        </div>
      </div>

        <div class="row">
          <div class="col-sm-12 down" v-if="selectedLevel !== 'landelijk'">
            <card :cardId="`Medicatie thuiswonenden in ${selectedYear}`" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de database met CBS microdata. In deze grafiek is het aantal thuiswonende mensen met dementie dat medicatie gebruikt als percentage van het totaal aantal thuiswonende mensen met dementie weergegeven.'" 
              :x="zorgkantoorregionaam_m" :y="gebruikt_medicatie_table.map(value => `${value}%`)"
              :xtitle="'Regio naam'" :ytitle="'Percentage'" 
              :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 900">
              <template slot="card-header">Medicatie thuiswonenden in {{selectedYear}}</template>
              <div slot="card-body">
                In aflopende volgorde van ofwel zorgkantoren ofwel dementienetwerken is het percentage te zien van thuiswonende mensen met dementie dat in {{selectedYear}} medicatie heeft voorgeschreven gekregen.
                  <stacked-bar-chart-colors v-if="load.graphOne" 
                  :ytitle="'% met medicatie'" 
                  :names="['%Gebruikt medicatie']" 
                  :x="[zorgkantoorregionaam_m]" 
                  :y="[gebruikt_medicatie]"
                  :ticksuffix="'%'"
                  :showlegend="false"/>
              </div>
            </card>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 down" v-if="selectedLevel !== 'landelijk'">
            <card :cardId="`Percentage met medicatie in ${selectedYear}`" :hovertext="'De data die wordt gebruikt voor de thuiswonende mensen met dementie in deze grafiek komt uit de database met CBS microdata. De informatie over het percentage medicatiegebruik van de verschillende leeftijdsgroepen in de algemene bevolkingsgroepen komt uit: https://opendata.cbs.nl/#/CBS/nl/dataset/81071ned/table?defaultview'" 
              :x="groep" :y="percentage_met_medicatie_table.map(value => `${value}%`)"
              :xtitle="'Groep'" :ytitle="'Percentage'" 
              :tableHeight="300">
              <template slot="card-header" >Percentage met medicatie in {{selectedYear}} </template>
              <div slot="card-body" style="height: 30rem">
                Voor de algemene bevolking is weergegeven welk percentage medicatie heeft voorgeschreven gekregen in {{selectedYear}}.
                <horizontal-bar-chart v-if="load.graphFour" :xtitle="'% met medicatie'" :x="percentage_met_medicatie" :y="groep" :marginleft="400" :height="400" :width="1200"/>
              </div>
            </card>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 down" v-if="selectedLevel !== 'landelijk'">
            <card :cardId="`Psychofarmaca thuiswonenden in ${selectedYear}`" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de database met CBS microdata. In deze grafiek is het aantal thuiswonende mensen met dementie dat psychofarmaca gebruikt als percentage van het totaal aantal thuiswonende mensen met dementie weergegeven.'" 
              :x="zorgkantoorregionaam_ps" :y="gebruikt_psycho_table.map(value => `${value}%`)"
              :xtitle="'Regio naam'" :ytitle="'Percentage'" 
              :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 900">
              <template slot="card-header">Psychofarmaca thuiswonenden in {{selectedYear}}</template>
              <div slot="card-body">
                In dit overzicht is het percentage te zien van thuiswonende mensen met dementie dat in {{selectedYear}} psychofarmaca voorgeschreven heeft gekregen.
                <stacked-bar-chart-colors v-if="load.graphOne" 
                :ytitle="'% met psychofarmaca'" 
                :names="['%Gebruikt psychofarmaca']" 
                :x="[zorgkantoorregionaam_ps]" 
                :y="[gebruikt_psycho]"
                :ticksuffix="'%'"
                :showlegend="false"/>
              </div>
            </card>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 down" v-if="selectedLevel !== 'landelijk'">
            <card :cardId="`Psychofarmacaverstrekking in ${selectedYear}`" :hovertext="'De indeling naar type ondersteuning zoals gebruikt door het CBS is aangehouden. De informatie over de mensen met dementie komt uit de database met CBS microdata. De informatie over de algemene bevolkingsgroepen komt uit: https://opendata.cbs.nl/#/CBS/nl/dataset/81071ned/table?defaultview'" 
              :x="bevolkingsgroep" 
              :y="antidepressiva_table.map(value => `${value}%`)" :z="hypnotica_table.map(value => `${value}%`)" 
              :w="anxiolytica_table.map(value => `${value}%`)" :v="antipsychotica_table.map(value => `${value}%`)" 
              :t="antidementie_table.map(value => `${value}%`)"
              :xtitle="'Groep'" :ytitle="'Antidepressiva'" :ztitle="'Hypnotica'" :wtitle="'Anxiolytica'"
              :vtitle="'Antipsychotica'" :ttitle="'Antidementie medicatie'"
              :tableHeight="300">
              <template slot="card-header">Psychofarmacaverstrekking in {{selectedYear}} </template>
              <div slot="card-body">
                Percentage personen dat 1 of meer verschillende typen psychofarmaca verstrekt kreeg (percentage van de totale groep) in {{selectedYear}}.
                  <grouped-bar-chart :ytitle="'% met medicatie'"  :tickangle="0" v-if="load.graphThree"  :tickformat="',.0%'"
                  :names="['Antidepressiva','Hypnotica','Anxiolytica','Antipsychotica','Antidementie medicatie']"
                  :x="[bevolkingsgroep,bevolkingsgroep,bevolkingsgroep,bevolkingsgroep,bevolkingsgroep]"
                  :y="[antidepressiva,hypnotica,anxiolytica,antipsychotica,antidementie]"
                  />
              </div>
            </card>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 down" v-if="selectedLevel !== 'landelijk'">
            <card :cardId="`Polyfarmacie thuiswonenden in ${selectedYear}`" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de database met CBS microdata. In deze grafiek is het aantal thuiswonende mensen met dementie dat polyfarmacie gebruikt als percentage van het totaal aantal thuiswonende mensen met dementie weergegeven.'" 
              :x="zorgkantoorregionaam_pl" :y="perc_poly_table.map(value => `${value}%`)"
              :xtitle="'Regio naam'" :ytitle="'Percentage'" 
              :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 900">
              <template slot="card-header">Polyfarmacie thuiswonenden in {{selectedYear}}</template>
              <div slot="card-body">
                In dit overzicht is het percentage te zien van thuiswonende mensen met dementie dat in 2019 vijf of meer medicijnen heeft voorgeschreven gekregen.
                <stacked-bar-chart-colors v-if="load.graphOne" 
                :ytitle="'% met polyfarmacie'" 
                :names="['%Gebruikt psychofarmaca']" 
                :x="[zorgkantoorregionaam_pl]" 
                :y="[perc_poly]" 
                :ticksuffix="'%'"
                :showlegend="false"/>
              </div>
            </card>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 down" v-if="selectedLevel !== 'landelijk'">
            <card :cardId="`Polyfarmacie per leeftijd in ${selectedYear}`" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database met microdata. Deze grafiek gaat uitsluitend over mensen met dementie die thuiswonen.'" 
              :x="leeftijd" :y="perc_polyfarmacie_table.map(value => `${value}%`)"
              :xtitle="'Leeftijdsgroep'" :ytitle="'Percentage'" 
              :tableHeight="300">
              <template slot="card-header">Polyfarmacie per leeftijd in {{selectedYear}} </template>
              <div slot="card-body" style="height: 30rem">
                In deze grafiek is in verschillende leeftijdsklassen het percentage te zien van thuiswonende mensen met dementie dat in {{selectedYear}} vijf of meer medicijnen heeft voorgeschreven gekregen.
                <simple-bar-chart-v v-if="load.graphFive" :xtitle="'Leeftijdsgroep'" :ytitle="'% met polyfarmacie'" :x="leeftijd" :y="perc_polyfarmacie"/>
              </div>
            </card>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 down" v-if="selectedLevel !== 'landelijk'">
            <card :cardId="`Meest verstrekte medicatie in ${selectedYear}`" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de database met CBS microdata. Hierin wordt de medicatie van de thuiswonende groep mensen met dementie weergegeven.'" 
              :x="medicijn" :y="aantal.map(value => `${value}`)"
              :xtitle="'Medicatie'" :ytitle="'Aantal'" 
              :tableHeight="500">
              <template slot="card-header" >Meest verstrekte medicatie in {{selectedYear}}</template>
              <div slot="card-body">
                Top 10 verstrekte medicatie aan thuiswonende mensen met dementie als percentage van het totaal aantal verstrekkingen in {{selectedYear}}.
                 <horizontal-bar-chart v-if="load.graphTwo" :xtitle="'% met medicatie'" :x="aantal" :y="medicijn" :marginleft="400" :height="400" :width="1200"/>
              </div>
            </card>
          </div>
      </div>

      <!-- Landelijk Plots -->
      <div class="row" v-if="selectedLevel === 'landelijk'">
            <div class="col-sm-12 down">
              <card :cardId="'Medicatie thuiswonenden'" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.'" 
                :x="medicatie_jaar" :y="gebruikt_medicatie_table.map(value => `${value}`)"
                :xtitle="'Jaar'" :ytitle="'Percentage'" 
                :tableHeight="300">
                <template slot="card-header">Medicatie thuiswonenden</template>
                <div slot="card-body">
                  Onderstaande grafiek geeft het landelijk gemiddelde weer van het percentage thuiswonende dementerenden dat medicijnen kreeg voorgeschreven.
                  <stacked-bar-chart-colors v-if="load.graphSix" :isLandelijk='false'
                    :ytitle="'% met medicatie'" :tickangle="0"
                    :xtitle="'Jaar'"
                    :x="[medicatie_jaar]"
                    :y="[gebruikt_medicatie]"
                    :showlegend="false"
                    :ticksuffix="'%'"/>
                </div>
              </card>
            </div>
        </div>

        <div class="row" v-if="selectedLevel === 'landelijk'">
            <div class="col-sm-12 down">
              <card :cardId="'Psychofarmaca thuiswonenden'" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.'" 
                :x="medicatie_jaar" :y="gebruikt_psycho_table.map(value => `${value}`)"
                :xtitle="'Jaar'" :ytitle="'Percentage'" 
                :tableHeight="300">
                <template slot="card-header">Psychofarmaca thuiswonenden</template>
                <div slot="card-body">
                  Deze grafiek toont het landelijk gemiddelde van het percentage thuiswonende dementerenden dat psychofarmaca heeft voorgeschreven.
                  <stacked-bar-chart-colors v-if="load.graphSix" :isLandelijk='false'
                    :ytitle="'% met psychofarmaca'" :tickangle="0"
                    :xtitle="'Jaar'" 
                    :x="[medicatie_jaar]"
                    :y="[gebruikt_psycho]"
                    :showlegend="false"
                    :ticksuffix="'%'"/>
                </div>
              </card>
            </div>
        </div>

        <div class="row" v-if="selectedLevel === 'landelijk'">
            <div class="col-sm-12 down">
              <card :cardId="'Polyfarmacie thuiswonenden'" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.'" 
                :x="medicatie_jaar" :y="perc_poly_table.map(value => `${value}`)"
                :xtitle="'Jaar'" :ytitle="'Percentage'" 
                :tableHeight="300">
                <template slot="card-header">Polyfarmacie thuiswonenden</template>
                <div slot="card-body">
                  Dit overzicht toont het landelijk gemiddelde van het percentage thuiswonende mensen met dementie dat vijf of meer medicijnen heeft voorgeschreven.
                  <stacked-bar-chart-colors v-if="load.graphSix" :isLandelijk='false'
                    :ytitle="'% met psychofarmaca'" :tickangle="0"
                    :xtitle="'Jaar'"
                    :x="[medicatie_jaar]"
                    :y="[perc_poly]"
                    :showlegend="false"
                    :ticksuffix="'%'"/>
                </div>
              </card>
            </div>
        </div>

        <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo"/>
  </div>
</template>

<script>
// import SideBarRight from '../components/SideBarRight'
import Card from '../components/Card'
import HorizontalBarChart from '../components/HorizontalBarChart'
import GroupedBarChart from '../components/GroupedBarChart'
import StackedBarChartColors from '../components/StackedBarChartColors'
import SimpleBarChartV from '../components/SimpleBarChartV'
import { mapGetters } from 'vuex'
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta'

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Thema Medicatie',
  },
  components: { Card, HorizontalBarChart, StackedBarChartColors, SimpleBarChartV , GroupedBarChart, Footer},
  data () {
    return {
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
      zorgkantoorregionaam_m: [],
      zorgkantoorregionaam_ps: [],
      zorgkantoorregionaam_pl: [],
      medicatie_jaar: [],
      gebruikt_medicatie: [],
      leeftijd: [],
      gebruikt_medicatie_table: [], 
      gebruikt_psycho_table:[], 
      perc_poly_table:[],
      perc_polyfarmacie_table: [],
      perc_polyfarmacie: [],
      gebruikt_psycho: [],
      perc_poly: [],
      medicijn: [],
      aantal: [],
      load: {
        graphOne: false,
        graphTwo: false,
        graphThree: false,
        graphFour: false,
        graphFive: false,
        graphSix: false
      },
      bevolkingsgroep: [],
      antidepressiva: [],
      antidementie:[],
      hypnotica: [],
      anxiolytica: [],
      antipsychotica: [],

      antidepressiva_table: [],
      antidementie_table:[],
      hypnotica_table: [],
      anxiolytica_table: [],
      antipsychotica_table: [],
      groep: [],
      percentage_met_medicatie: [],
      percentage_met_medicatie_table: [],
    }
  },
  watch: {
    '$store.state.selectedLevel': function (level) {
      this.loadAllData(level)
    },
    '$store.state.selectedYear': function () {
      this.load.graphThree= false
      this.loadAllData(this.$store.state.selectedLevel)
      this.loadMedicatieData()
    }
  },
  computed: {
      ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear'])
  },
  methods: {
    loadAllData (level) {
      if (level === 'zorgkantoor') {
        this.$http.get(`/api/zorgkantoor_medicatie/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a,b) => b.percentage_medicatie-a.percentage_medicatie)
            // response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Landelijk')
            this.gebruikt_medicatie = response.data.map(d => (d.percentage_medicatie*100))
            this.zorgkantoorregionaam_m = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a,b) => b.percentage_psychofarmaca-a.percentage_psychofarmaca)
            this.gebruikt_psycho = response.data.map(d => (d.percentage_psychofarmaca*100))
            this.zorgkantoorregionaam_ps = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a,b) => b.percentage_polyfarmacieZonderJ-a.percentage_polyfarmacieZonderJ)
            this.perc_poly = response.data.map(d => (d.percentage_polyfarmacieZonderJ*100))
            this.zorgkantoorregionaam_pl = response.data.map(d => d.zorgkantoorregionaam)

            this.gebruikt_medicatie_table = response.data.map(d => (d.percentage_medicatie*100).toFixed(1))
            this.gebruikt_psycho_table = response.data.map(d => (d.percentage_psychofarmaca*100).toFixed(1))
            this.perc_poly_table = response.data.map(d => (d.percentage_polyfarmacieZonderJ*100).toFixed(1))
            this.load.graphOne=true
          }
        )
      }
      else if(level === 'landelijk'){
        this.$http.get(`/api/zorgkantoor_medicatie/get_data/?landelijk=True`).then(
          (response) => {
            response.data.sort((a,b) => a.jaar - b.jaar)
            this.medicatie_jaar = response.data.map(d => d.jaar)
            this.gebruikt_medicatie = response.data.map(d => (d.percentage_medicatie*100))
            this.zorgkantoorregionaam_m = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a,b) => b.percentage_psychofarmaca-a.percentage_psychofarmaca)
            this.gebruikt_psycho = response.data.map(d => (d.percentage_psychofarmaca*100))
            this.zorgkantoorregionaam_ps = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a,b) => b.percentage_polyfarmacieZonderJ-a.percentage_polyfarmacieZonderJ)
            this.perc_poly = response.data.map(d => (d.percentage_polyfarmacieZonderJ*100))
            this.zorgkantoorregionaam_pl = response.data.map(d => d.zorgkantoorregionaam)

            this.gebruikt_medicatie_table = response.data.map(d => (d.percentage_medicatie*100).toFixed(1))
            this.gebruikt_psycho_table = response.data.map(d => (d.percentage_psychofarmaca*100).toFixed(1))
            this.perc_poly_table = response.data.map(d => (d.percentage_polyfarmacieZonderJ*100).toFixed(1))
            this.load.graphSix=true
          }
        )
      }
      else {
        this.$http.get(`/api/dementienetwerk_medicatie/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a,b) => b.percentage_medicatie-a.percentage_medicatie)
            response.data = response.data.filter(x => x.dementienetwerknaam !=='Landelijk')
            this.gebruikt_medicatie = response.data.map(d => (d.percentage_medicatie*100))
            this.zorgkantoorregionaam_m = response.data.map(d => d.dementienetwerknaam)
            response.data.sort((a,b) => b.percentage_psychofarmaca-a.percentage_psychofarmaca)
            this.zorgkantoorregionaam_ps = response.data.map(d => d.dementienetwerknaam)
            this.gebruikt_psycho = response.data.map(d => (d.percentage_psychofarmaca*100))
            response.data.sort((a,b) => b.percentage_polyfarmacieZonderJ-a.percentage_polyfarmacieZonderJ)
            this.perc_poly = response.data.map(d => (d.percentage_polyfarmacieZonderJ*100))
            this.zorgkantoorregionaam_pl = response.data.map(d => d.dementienetwerknaam)

            this.gebruikt_medicatie_table = response.data.map(d => (d.percentage_medicatie*100).toFixed(1))
            this.gebruikt_psycho_table = response.data.map(d => (d.percentage_psychofarmaca*100).toFixed(1))
            this.perc_poly_table = response.data.map(d => (d.percentage_polyfarmacieZonderJ*100).toFixed(1))
            this.load.graphSix=true
          }
        )
      }
    },
    loadMedicatieData () {
      this.$http.get(`/api/medicatie_leeftijd/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          response.data.sort((a,b) => a.leeftijd_order-b.leeftijd_order)
          this.perc_polyfarmacie = response.data.map(d => (d.aandeel_polyfarmacie))
          this.perc_polyfarmacie_table = response.data.map(d => (d.aandeel_polyfarmacie*100).toFixed(1))
          this.leeftijd = response.data.map(d => d.leeftijd)
          this.load.graphFive = true
        }
      ),
      this.$http.get(`/api/bevolking_medicatie/get_data/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          this.bevolkingsgroep = response.data.map(d => d.groep)
          this.antidepressiva = response.data.map(d => (d.percentage_antidepressiva))
          this.antidementie = response.data.map(d => (d.percentage_antidementie))
          this.hypnotica = response.data.map(d => (d.percentage_hypnotica))
          this.anxiolytica = response.data.map(d => (d.percentage_anxiolytica))
          this.antipsychotica = response.data.map(d => (d.percentage_antipsychotica))

          this.antidepressiva_table = response.data.map(d => (d.percentage_antidepressiva*100).toFixed(1))
          this.antidementie_table = response.data.map(d => (d.percentage_antidementie*100).toFixed(1))
          this.hypnotica_table = response.data.map(d => (d.percentage_hypnotica*100).toFixed(1))
          this.anxiolytica_table = response.data.map(d => (d.percentage_anxiolytica*100).toFixed(1))
          this.antipsychotica_table = response.data.map(d => (d.percentage_antipsychotica*100).toFixed(1))
          this.load.graphThree=true
        }
      ),
      this.$http.get(`/api/medicatie/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          response.data.sort((a,b) => a.aantal-b.aantal)
          this.medicijn = response.data.map(d => d.ATC).slice(-10)
          this.aantal = response.data.map(d => (d.aantal/d.observaties)).slice(-10)
          this.load.graphTwo=true
        }
      ),
      this.$http.get(`/api/bevolking_medicatie/get_data_medicatie/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          response.data.sort((a,b) => a.aantal-b.aantal)
          this.percentage_met_medicatie = response.data.map(d => (d.percentage_met_medicatie))
          this.percentage_met_medicatie_table = response.data.map(d => (d.percentage_met_medicatie*100).toFixed(1))
          this.groep = response.data.map(d => d.groep)
          this.load.graphFour=true
        }
      )
    }
  },
  mounted () {
        this.loadAllData(this.selectedLevel),
        this.loadMedicatieData()
      }
    }

</script>

<style scoped>

.col-sm-5 {
  margin-top: 10px !important;
}

.blue {
  color: var(--color-1);
}

.table-bg {
  color: white;
  background-color: var(--color-1);
  border-color: var(--color-1);
}

.custom-margin{
  margin: 20px 0 20px 0;
}

.custom-font{
  font-size: 12px;
}

.custom-link {
  margin-right: 0px !important;
  outline: none;
  text-decoration: none;
  padding: 3px 3px 3px;
}


.intro-section-title{
  font-weight: bold;
  margin-bottom: 2rem;
}

/*.intro-section-text{
  color: #1f2a44;
}*/
</style>
